<template>
   <div>
	  <b-row class="mt-3">
		 <b-col class="text-center"
				md="8"
				offset-md="2">
			<h2>{{ $t("pages.eimaste-dipla") }}</h2>
			<h4 class="font-weight-normal">{{ $t("pages.eimaste-dipla-text") }}</h4>
		 </b-col>
	  </b-row>
	  <b-row class="mt-3 match-height">
		 <b-col md="1"></b-col>
		 <b-col md="5">
			<b-card class="shadow-none py-1 faq-contact-card text-center">
			   <b-avatar class="mb-2"
						 rounded
						 size="42"
						 variant="light-primary">
				  <feather-icon icon="MailIcon"
								size="18" />
			   </b-avatar>
			   <h4>info@beezio.app</h4>
			   <span class="text-body">{{ $t("pages.mail-text") }}</span>

			</b-card>
		 </b-col>
		 <b-col md="5">
			<b-card
				class="shadow-none py-1 faq-contact-card text-center mt-1 mt-md-0"
			>
			   <b-avatar class="mb-2"
						 rounded
						 size="42"
						 variant="light-primary">
				  <feather-icon icon="PhoneCallIcon"
								size="18" />
			   </b-avatar>
			   <h4>22960 22477</h4>
			   <span class="text-body">{{ $t("pages.phone-text") }}</span>
			</b-card>
		 </b-col>
		 <b-col md="1"></b-col>
	  </b-row>
   </div>
</template>

<script>
import {BCard, BRow, BCol, BAvatar} from "bootstrap-vue";

export default {
   components: {
	  BCard,
	  BRow,
	  BCol,
	  BAvatar,
   },
   name: "Support",
};
</script>

<style scoped>
</style>